import * as React from "react";
import FixedImage from "@components/Image/FixedImage";
import YellowButton from "@components/Button/YellowButton";
import { DateTime } from "luxon";
import { FixedObject } from "gatsby-image";
import "@themes/article-card.scss";
import "@themes/image.scss";
import "@themes/text.scss";

export interface ArticleCardProps {
    image?: FixedObject;
    title: string;
    subTitle: string;
    to: string;
    titleLocation: "top" | "bottom";
    date?: string;
}

const ArticleCard = (props: ArticleCardProps) => {
    const { image, title, subTitle, to, date, titleLocation } = props;
    return (
        <div className="article-card">
            <div className="top-container">
                {titleLocation === "top" && (
                    <>
                        <h2>{title}</h2>
                        {date && (
                            <time className="published">
                                {DateTime.fromFormat(
                                    date,
                                    "yyyy-MM-dd"
                                ).toLocaleString(DateTime.DATE_FULL)}
                            </time>
                        )}
                    </>
                )}
                {image && (
                    <div className="block-card-image-container">
                        <FixedImage className="image-wrapper" src={image} />
                    </div>
                )}
                <div className="detail-container">
                    {titleLocation === "bottom" && <h2>{title}</h2>}
                    {titleLocation !== "bottom" && <br />}
                    <p>{subTitle}</p>
                </div>
            </div>
            <div className="bottom-container">
                <YellowButton to={to} target="_blank">
                    read more...
                </YellowButton>
            </div>
        </div>
    );
};

export default ArticleCard;
