import * as React from "react";
import { PureComponent } from "react";
import { graphql, PageProps } from "gatsby";
import { Markdowns, PageContext } from "../models/pageModel";

import Layout from "@components/Layout";
import SEO from "@components/seo";
import ArticleCard from "@components/Container/ArticleCard";
import Column from "@components/Container/Column";
import Row from "@components/Container/Row";

export interface PortfoliosPageContext {
    markdowns: Markdowns;
}

export type PortfoliosProps = PageContext<PortfoliosPageContext> & PageProps;

export default class Portfolios extends PureComponent<PortfoliosProps> {
    constructor(props) {
        super(props);
    }

    renderDescriptiveCard = () => {
        const { markdowns } = this.props.data;
        return markdowns.edges.map((edge, index) => {
            const { image, title, summary, slug } = edge.node.frontmatter;
            return (
                <ArticleCard
                    image={image.childImageSharp.fixed}
                    title={title}
                    subTitle={summary}
                    to={slug}
                    key={index}
                    titleLocation="bottom"
                />
            );
        });
    };

    render() {
        const { path } = this.props;
        return (
            <Layout>
                <SEO
                    title="Projects"
                    description="Here is where Ng Chen Hon shares his tech/pet projects with the world"
                    keywords={[
                        "Ng Chen Hon",
                        "Projects",
                        "ng chen hon's projects",
                        "Mutatedbread's projects",
                    ]}
                    pagePath={path}
                />
                <Row>
                    <Column>{this.renderDescriptiveCard()}</Column>
                </Row>
            </Layout>
        );
    }
}

export const query = graphql`
    query {
        markdowns: allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { type: { eq: "portfolio" } } }
        ) {
            totalCount
            edges {
                node {
                    id
                    frontmatter {
                        shortTitle
                        summary
                        slug
                        title
                        image {
                            childImageSharp {
                                fixed(width: 720, height: 288) {
                                    ...GatsbyImageSharpFixed
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
