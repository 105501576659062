import * as React from "react";
import { Link } from "gatsby";
import "@themes/button.scss";

export interface YellowButtonProps {
	children: string;
	to?: string;
	href?: string;
	isDownload?: string;
	target?: string;
}

const YellowButton = (props: YellowButtonProps) => {
	const { children, to, href, isDownload, target } = props;
	return (
		<button className="button-primary">
			{to ? (
				<Link to={to} target={target}>
					{children}
				</Link>
			) : href ? (
				<a href={href} download={isDownload} target={target}>
					{children}
				</a>
			) : (
				<p>{children}</p>
			)}
		</button>
	);
};

export default YellowButton;
