import * as React from "react";
import Img, { FixedObject } from "gatsby-image";

export interface FixedImageProps {
	src: FixedObject;
	style?: React.CSSProperties;
	className?: string;
}

const FixedImage = (props: FixedImageProps) => {
	const { src, style, className } = props;
	return <Img style={style} className={className} fixed={src} />;
};

export default FixedImage;
